import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaBriefcase, FaGraduationCap, FaTools, FaThumbtack } from "react-icons/fa";
import "../App.css";

function About() {
    const [profile, setProfile] = useState(null);

    useEffect(() => {
        fetch("/content/profile.json")
            .then((response) => response.json())
            .then((data) => setProfile(data))
            .catch((error) => console.error("Error loading profile:", error));
    }, []);

    if (!profile) return <div className="text-center px-5"><div className="spinner-border text-primary"></div></div>;

    const formatYear = (dateString) => dateString === "Present" ? "Present" : new Date(dateString).getFullYear();

    return (
        <div className="container">
            {/* Summary Section */}
            <h2 className="fw-bold  section-heading section-title">
                    <FaThumbtack className="me-2 icon-shake" /> Summary
            </h2>
            <div className="card shadow-sm text-body  p-2" dangerouslySetInnerHTML={{ __html: profile.summary }}></div>
       

            {/* Skills Section */}
            <div className="mt-5">
                <h2 className="fw-bold section-heading section-title">
                    <FaTools className="me-2 icon-shake" /> Skills
                </h2>
                {/* Skill Summary (New Addition) */}
                {profile.skillSummary && (
                    <div className="card shadow-sm mb-3 p-3 " >
                        
                            <div className="text-body" dangerouslySetInnerHTML={{ __html: profile.skillSummary }}></div>

                            <div className="d-flex flex-wrap gap-2">
                            {profile.skills.map((skill, index) => (
                                <span key={index} className="badge bg-primary p-2 fs-6">{skill}</span>
                            ))}
                        </div>

                    </div>
                )}
               
            </div>

            {/* Experience Section */}
            <div className="mt-5">
                <h2 className="fw-bold section-heading section-title">
                    <FaBriefcase className="me-2 icon-shake" /> Experience
                </h2>
                <div className="row">
                    {profile.experience.map((job, index) => (
                        <div key={index} className="col-12 mb-4">
                            <div className="card shadow-sm p-1 d-flex flex-row align-items-start">

                                {/* Placeholder for Company Logo */}
                                <img src={job.companyLogo || "/images/company-placeholder.png"}
                                    alt="Company Logo"
                                    className="company-logo icon-shake me-1" />

                                <div className="flex-grow-1">
                                    {/* Job Title */}
                                    <h4 className="fw-bold">{job.role} 
                                        {job.skillToHighlight && (
                                             <span className="text-muted"> | {job.skillToHighlight}</span>
                                        )} 
                                    </h4>

                                    {/* Company & Employment Details */}
                                    <p className="text-muted m-0">
                                        <strong>{job.company}</strong> · {job.employment_type}
                                    </p>
                                    <p className="text-muted m-0">
                                        {formatYear(job.start_date)} - {formatYear(job.end_date)} · {job.location}
                                    </p>

                                    {/* Job Description */}
                                    <div dangerouslySetInnerHTML={{ __html: job.description }} className="mt-2 text-body"></div>

                                    {/* Skills Summary */}
                                    <p className="mt-2 fw-bold">
                                        <FaTools className="me-1" /> {job.skills.join(", ")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Education Section - Now Styled Like Experience (Purple Color) */}
            <div className="mt-5">
                <h2 className="fw-bold section-heading section-title">
                    <FaGraduationCap className="me-2 icon-shake" /> Education
                </h2>
                <div className="row">
                    {profile.education.map((edu, index) => (
                        <div key={index} className="col-12 mb-4">
                            <div className="card shadow-sm p-3 d-flex flex-row align-items-start">

                                {/* Placeholder for Institution Logo */}
                                <img src={edu.institutionLogo || "/images/education-placeholder.png"}
                                    alt="Institution Logo"
                                    className="company-logo icon-shake me-3" />

                                <div className="flex-grow-1">
                                    {/* Degree and Institution */}
                                    <h4 className="fw-bold">{edu.degree}</h4>
                                    <p className="text-muted m-0"><strong>{edu.institution}</strong></p>
                                    <p className="text-muted m-0">{formatYear(edu.start_date)} - {formatYear(edu.end_date)}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default About;
