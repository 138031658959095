import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
 


function TypeWriter({ textsArray, className })
{
   
    const [displayText, setDisplayText] = useState(""); // State for typewriter effect
    

    // Typewriter Effect Logic
    useEffect(() => {
        if (!textsArray || textsArray.length === 0) return; // Ensure titles exist

        let charIndex = 0;
        let isDeleting = false;
        let currentTitleIndex = 0;

        const typeWriter = () => {
            const currentTitle = textsArray[currentTitleIndex];
            const cursor = ""; // Define the blinking caret

            if (!isDeleting) {
                setDisplayText(`${currentTitle.substring(0, charIndex + 1)}${cursor}`);
                charIndex++;

                if (charIndex === currentTitle.length) {
                    isDeleting = true;
                    setTimeout(typeWriter, 2000); // Pause before deleting
                    return;
                }
            } else {
                setDisplayText(`${currentTitle.substring(0, charIndex - 1)}${cursor}`);
                charIndex--;

                if (charIndex === 0) {
                    isDeleting = false;
                    currentTitleIndex = (currentTitleIndex + 1) % textsArray.length; // Switch to next title
                }
            }
            setTimeout(typeWriter, isDeleting ? 50 : 80); // Speed control
        };


        const timer = setTimeout(typeWriter, 1000); // Start after a delay

        return () => clearTimeout(timer);
    }, [textsArray]); // Runs effect when titles are available


    return (
        <p class ={className}>{displayText}<span class="caret">|</span></p>
    );


}

export default TypeWriter;