import React from "react";
import { NavLink } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import { FaHome, FaUser, FaBriefcase, FaBook, FaEnvelope } from "react-icons/fa";

function Navbar() {
    return (
        <nav className="navbar shadow-sm sticky-top">
            <div className="container d-flex justify-content-center">
                <div className="d-flex flex-row gap-4 gap-md-5">

                    {/* Home */}
                    <div className="nav-item text-center">
                        <NavLink className="nav-link d-flex flex-column align-items-center" to="/" end>
                            <FaHome size={22} />
                            <span className="small d-none d-md-block">Home</span>
                        </NavLink>
                    </div>

                    {/* Profile */}
                    <div className="nav-item text-center">
                        <NavLink className="nav-link d-flex flex-column align-items-center" to="/page/profile">
                            <FaUser size={22} />
                            <span className="small d-none d-md-block">Profile</span>
                        </NavLink>
                    </div>

                    {/* Portfolio */}
                    <div className="nav-item text-center">
                        <NavLink className="nav-link d-flex flex-column align-items-center" to="/page/portfolio">
                            <FaBriefcase size={22} />
                            <span className="small d-none d-md-block">Portfolio</span>
                        </NavLink>
                    </div>

                    {/* Articles */}
                    <div className="nav-item text-center">
                        <NavLink className="nav-link d-flex flex-column align-items-center" to="/page/articles">
                            <FaBook size={22} />
                            <span className="small d-none d-md-block">Articles</span>
                        </NavLink>
                    </div>

                    {/* Contact */}
                    <div className="nav-item text-center">
                        <NavLink className="nav-link d-flex flex-column align-items-center" to="/page/contact">
                            <FaEnvelope size={22} />
                            <span className="small d-none d-md-block">Contact</span>
                        </NavLink>
                    </div>

                </div>
            </div>
        </nav>
    );
}

export default Navbar;
