import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaExternalLinkAlt,FaBook } from "react-icons/fa";

function Articles() {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        fetch("/content/articles.json")
            .then(response => response.json())
            .then(data => setArticles(data))
            .catch(error => console.error("Error loading articles:", error));
    }, []);

    if (!articles.length) return <div className="text-center px-5"><div className="spinner-border text-primary"></div></div>;

    return (
        <>
            
            <h2 className="fw-bold section-heading section-title">
                <FaBook className="me-2 icon-shake" />My Articles
            </h2>
            <p className="lead text-center text-theme-muted">Latest articles from my blog.</p>

            <div className="row mt-4">
                {articles.map((article, index) => (
                    <div key={index} className="col-12 mb-4">
                        <div className="card shadow-sm p-4 border-0 rounded-4">
                            <h4 className="fw-bold text-primary">{article.title}</h4>
                            <p className="text-muted">{new Date(article.pubDate).toDateString()}</p>

                            <p className="d-inline text-body">
                                {article.summary}
                                <Link to={`/page/articles/${article.id}`} className="btn btn-link btn-sm text-primary float-end">
                                    Read More <FaExternalLinkAlt size={12} />
                                </Link>
                            </p>

                           

                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export default Articles;
