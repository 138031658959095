import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaBriefcase, FaTools, FaExternalLinkAlt } from "react-icons/fa";
import "../App.css";

function Portfolio() {
    const [projects, setProjects] = useState([]);

    useEffect(() => {
        fetch("/content/portfolio.json")
            .then((response) => response.json())
            .then((data) => setProjects(data.projects))
            .catch((error) => console.error("Error loading portfolio:", error));
    }, []);

    if (!projects.length) return <div className="text-center py-5"><div className="spinner-border text-primary"></div></div>;

    return (
        <>
            <h2 className="fw-bold section-heading section-title">
                <FaBriefcase className="me-2 icon-shake" /> Portfolio
            </h2>

            <div className="row">
                {projects.map((project, index) => (
                    <div key={index} className="col-12 mb-4">
                        <div className="card shadow-sm p-3 d-flex flex-row align-items-start">

                            {project.projectLogo && (
                                <img  src={project.projectLogo} alt="Company Logo" className="company-logo icon-shake me-3" />
                            )}
                            {!project.projectLogo && (
                                <span   className="me-4" />
                            )}
                            

                            <div className="flex-grow-1">
                                {/* Role and Skills */}
                               

                                {/* Company Name and Duration */}
                                <p className="fw-bold m-0">
                                    <strong>{project.projectTitle}</strong>
                                    {project.projectLink && (
                                        <a href={project.projectLink} target="_blank" rel="noopener noreferrer" className="ms-2">
                                            <FaExternalLinkAlt />
                                        </a>
                                    )}
                                </p>
                                <p className="text-muted m-0">{project.duration}</p>                          

                                <p className="mt-2 text-body" dangerouslySetInnerHTML={{ __html: project.description }}></p>
                                <p className="mt-2 text-muted" dangerouslySetInnerHTML={{ __html: project.responsibilities }}></p>
                          
                               
                                {project.environment && (
                                <p className="mt-2 fw-bold">
                                    <FaTools className="me-1" /> {project.environment}
                                </p>
                                )}
                               
                              
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export default Portfolio;
