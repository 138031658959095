import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaEnvelope, FaLinkedin } from "react-icons/fa";
import "../App.css";

function Contact() {
    const [contactInfo, setContactInfo] = useState(null);

    useEffect(() => {
        fetch("/content/profile.json")
            .then((response) => response.json())
            .then((data) => setContactInfo(data.contactMe))
            .catch((error) => console.error("Error loading contact info:", error));
    }, []);

    if (!contactInfo) return (
        <div className="px-5">
            <div className="spinner-border text-primary"></div>
        </div>
    );

    return (
        <div className="px-4">
            <h2 className="fw-bold section-title">Contact Me</h2>
            <p className="text-theme-muted">{contactInfo.summary}</p>

            {/* Email Section */}
            <div className="mt-4">
               
                <a href={`mailto:${contactInfo.email}`}  className="text-info text-decoration-none">
                    <div className="d-flex align-items-center">
                        <FaEnvelope className="me-2 text-primary" size={18} />
                        <span className="fw-bold">Email</span>
                    </div>
                    <hr className="my-2 w-50" />
                    {contactInfo.email}
                </a>
            </div>

            {/* LinkedIn Section */}
            {contactInfo.linkedIn && contactInfo.linkedIn.trim() !== "" && (
                <div className="mt-4">

                    <a href={contactInfo.linkedIn} target="_blank" rel="noopener noreferrer"  className="text-info text-decoration-none">
                        <div className="d-flex align-items-center">
                            <FaLinkedin className="me-2 text-primary" size={18} />
                            <span className="fw-bold">LinkedIn</span>
                        </div>
                        <hr className="my-2 w-50" />
                        {contactInfo.linkedIn}
                    </a>
                </div>
            )}
        </div>
    );
}

export default Contact;
