import React from "react";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";

function NotFound() {
    return (
        <div className="container text-center d-flex flex-column align-items-center justify-content-center min-vh-100">
            <h1 className="display-1 fw-bold text-danger">404</h1>
            <h2 className="fw-bold text-theme-primary">Oops! Page Not Found</h2>
            <p className="lead text-theme-muted">The page you're looking for doesn't exist or has been moved.</p>
            <Link to="/" className="btn btn-custom btn-green mt-3">
                Go Back Home
            </Link>
        </div>
    );
}

export default NotFound;
