import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Home from "./pages/Home";
import Portfolio from "./pages/Portfolio";
import Articles from "./pages/Articles";
import ArticleDetails from "./pages/ArticleDetails";
import Profile from "./pages/Profile";
import Contact from "./pages/Contact";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import NotFound from "./pages/NotFound";

function HashRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    if (window.location.hash) {
      let currentRoute = window.location.hash.substring(1); // Remove "#"
      navigate("/page" + currentRoute); // Navigate to the correct route
    }
  }, []);

  return null; // This component doesn't render anything
}
function App() {
  return (
    <Router>
      <HashRedirect />
      <Navbar />
      
      {/* Main Content Wrapper */}
      <div className="main-content  d-flex flex-column  overflow-auto">
        <div className="container align-items-center mt-5">  {/* Standardized Container */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/page/portfolio" element={<Portfolio />} />
            <Route path="/page/articles" element={<Articles />} />
            <Route path="/page/profile" element={<Profile />} />
            <Route path="/page/contact" element={<Contact />} />
            <Route path="/page/articles/:id" element={<ArticleDetails />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </div>

      <Footer />
    </Router>
  );
}


export default App;
