import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaUser, FaBriefcase, FaBook } from "react-icons/fa"; // Icons
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import profileImage from "../assets/sandeep-dixit.jpeg";
import TypeWriter from "../components/TypeWriter";

function Home() {
    const [profile, setProfile] = useState({ name: "", summary: "", tagline: "", titles: [] });

    // Fetch profile data from profile.json
    useEffect(() => {
        fetch("/content/profile.json")
            .then(response => response.json())
            .then(data => setProfile(data.brief))
            .catch(error => console.error("Error loading profile:", error));
    }, []);

    return (

        //d-flex align-items-center justify-content-center vh-90
        <div className="d-flex flex-column flex-md-row  align-items-center justify-content-center vh-100">

            {/* Profile Image */}
            <div className="scale-changing-image-container">
                <img src={profileImage} alt="Profile" className="rounded-circle me-md-4 mb-4 mb-md-0 profile-img" />
            </div>

            {/* Text Content */}
            <div className="text-center text-md-start w-100">

                {/* Typewriter Effect for Name */}
                <h1 className="main-title">{profile.name}</h1>
                <TypeWriter textsArray={profile.titles} className="typewriter-text-enclosed" />

                {/* Tagline */}
                <p className="sub-text">&nbsp;{profile.tagline}</p>

                {/* About Section */}
                <div className="mt-4">
                    <h2 className="section-title">A Bit About Me</h2>
                </div>

                <p className="sub-text text-break text-wrap w-100">
                {profile.summary}
                </p>



                {/* Buttons with React Router Links */}
                <div className="d-flex justify-content-center justify-content-md-start gap-3 mt-4">
                    <Link to="/page/profile" className="btn btn-custom btn-primary d-flex align-items-center p-2 px-md-4">
                        <FaUser className="btn-icon shake" />
                        <span className="d-none d-md-inline ms-2">Profile</span> {/* Hide text on small screens */}
                    </Link>
                    <Link to="/page/portfolio" className="btn btn-custom btn-primary d-flex align-items-center p-2 px-md-4">
                        <FaBriefcase className="btn-icon shake" />
                        <span className="d-none d-md-inline ms-2">Portfolio</span>
                    </Link>
                    <Link to="/page/articles" className="btn btn-custom btn-primary d-flex align-items-center p-2 px-md-4">
                        <FaBook className="btn-icon shake" />
                        <span className="d-none d-md-inline ms-2">Articles</span>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Home;
